var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap-term" }, [
    _vm.showCloseBtn
      ? _c("div", {
          staticClass: "back-drop",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        })
      : _vm._e(),
    _c("div", { staticClass: "content" }, [
      _vm.showCloseBtn
        ? _c(
            "a",
            {
              staticClass: "block_close",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_c("v-icon", { attrs: { name: "close" } })],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _c(
        "div",
        { staticStyle: { width: "98vw" } },
        _vm._l(3, function (i) {
          return _c("img", {
            key: i,
            attrs: {
              src: `/img/faq/faq_page-00${i.toString().padStart(2, 0)}.jpg`,
              width: "100%",
              height: "100%",
            },
          })
        }),
        0
      ),
      _vm._m(1),
      _vm._m(2),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showBtn !== false,
              expression: "showBtn !== false",
            },
          ],
          staticClass: "button-accept",
        },
        [
          _c("v-button", {
            attrs: {
              label: "Li e aceito os termos de uso e política de privacidade",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("accept")
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "c14" }, [_c("span", { staticClass: "c13" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "c3" }, [_c("span", { staticClass: "c0" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "c3" }, [_c("span", { staticClass: "c0" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }