<script>
import Faq from '@/components/Faq.vue'

export default {
  name: 'FaqView',
  components: {
    Faq
  }
};
</script>

<template>
  <div class="faq-page">
    <faq :showBtn="false" :showCloseBtn="false"/>
  </div>
</template>

<style lang="postcss" scoped>
.faq-page {
  @media (min-width: 992px) {
    background-color: var(--color-blue-medium);
  }
  .faq{
    input{
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    a{
      color: var(--color-green);
      display: inline-block;
      vertical-align: middle;
    }
  }
}
</style>
